<template>
  <div class="sanwei-video">
    <!-- 横幅 -->
    <div class="banner">
      <img src="../../../assets/SanWeiGuZhen/SanWeiVideo/banner.png" alt="">
    </div>
    <!-- 内容 -->
    <div class="video-content">
      <!-- 导航标题 -->
      <div class="DaoHangTitle">
        <span @click="SanWeiGZ()">三维古镇</span>
        <div><img src="../../../assets/VRVisit/VideoLog/Rights.png" alt=""></div>
        <span>三维视频</span>
      </div>

      <!-- 大视频 -->
      <div class="bigVideo">
        <div class="video-content-left">
          <img class="bigv" src="../../../assets/SanWeiGuZhen/SanWeiVideo/BigVideo.png" alt="">
          <div class="zan-live-share">
            <!-- 点赞 -->
            <div class="zan" @click="liang()">
              <img class="zanImage" :src="zansrc" alt="">
              <span class="zan-text">点赞</span>
            </div>
            <!-- 收藏 -->
            <div class="live">
              <img src="../../../assets/SanWeiGuZhen/SanWeiVideo/Xing.png" alt="">
              <span class="zan-text">收藏</span>

            </div>
            <!-- 分享 -->
            <div class="share">
              <img src="../../../assets/SanWeiGuZhen/SanWeiVideo/ZhuanFa.png" alt="">
              <span class="zan-text">转发</span>
            </div>
          </div>
        </div>
        <!-- 视频内容信息 -->
        <div class="video-content-information">
          <div class="video-title">
            靖港古镇三维视频
          </div>
          <div class="video-time font">
            2021年12月23发布
          </div>
          <div class="zuopinlaiyuan font">
            作品来源：
          </div>
          <!--  -->
          <div class="introduce font">
            内容简介：
          </div>
        </div>
      </div>
    </div>
    <!-- 其他视频 -->
    <div class="otherVideo">
      <div class="otherVideo-item" v-for="item in 4" :key="item">
        <img class="smallv" @click="show(item - 1)" src="../../../assets/SanWeiGuZhen/SanWeiVideo/JG_1.png" alt="">
        <div class="otherVideo-name">
          <span> &nbsp; 靖港1</span>
        </div>
      </div>
    </div>
    <Bottom_Bar></Bottom_Bar>
    <Navigation_Buttons></Navigation_Buttons>
  </div>
</template>

<script>
import Navigation_Buttons from '../../Public/Navigation_Buttons'
import Bottom_Bar from '../../Public/Bottom_Bar'
let flag = false
export default {
  components: {
    Navigation_Buttons, Bottom_Bar
  },
  data() {
    return {
      zansrc: require('../../../assets/SanWeiGuZhen/SanWeiVideo/Zan.png'),
      bluezan: require('../../../assets/SanWeiGuZhen/blueZan.png'),
    }
  },
  created() {
    const axios = require("axios").default;
    // axios.<method> 能够提供自动完成和参数类型推断功能
    // 发起一个get请求
    axios({
      method: "GET",
      url: "http://8.134.93.240/virturaltravel/app/contentPublish/getWcKuaikuaiContent/90",
      data: this.data,

    })
      .then((res) => {
        var dataSource = res.data.data.contentPublish;
        let url = []
        console.log(dataSource);
        if (res.data.code == 0) {
          this.wc_kuaikuai = dataSource;
          this.zuopin_image = dataSource.imgs;
          // console.log(this.wc_kuaikuai);

          for (let i = 0; i <= 3; i++) {
            this.url.push(this.zuopin_image[i].resourceUrl)
          }
        }

      })
      .catch(function (error) {
        // 处理错误情况
        console.log(error);
      });
  },
  methods: {
    SanWeiGZ() {
      this.$router.push('/ancientTown')
    },
    show(index) {
      let bigv = document.querySelector('.bigv');
      let smv = document.querySelectorAll('.smallv')
      bigv.src = smv[index].src
    },
    liang() {
      let zan = document.querySelector('.zanImage');
      if (flag == false) {
        zan.src = this.bluezan
        flag = true
      } else {
        zan.src = this.zansrc
        flag = false
      }
    },
  }

}
</script>

<style lang="less" scoped>
@import url(./SanWeiVideo.less);
</style>